import React from "react"
import styled from "styled-components"
import { CheckedRight } from "../elements/checkmark.js"

const StepperWrap = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  border-radius: 8px;
  background-color: transparent;
  //rgb(26, 28, 10);
`
const StepWrap = styled.span`
  display: inline-flex;
  flex-flow: column nowrap;
  height: auto;
  margin: 0 0;
  text-align: center;
  align-items: center;
  width: 100%;
  background-color: transparent;
  color: white;
  flex-basis: 0;
`
const ProgressBar = styled.div`
  display: flex;
  width: 35%;
  background: #ccc;
  height: 1px;
  position: relative;
  align-items: center;
`
const ProgressStatus = styled.div`
  display: block;
  width: ${props => (props.progress ? props.progress.toString() + "%" : "0%")};
  background-image: linear-gradient(-325deg, #00b712 35%, #5aff15 95%);
  background-color: #00b712;
  height: 1px;
  transition: width 0.5s ease-out;
`
const StepLabel = styled.div`
  margin-top: 5px;
  font-size: 12px;
  word-break: break-all;
`
const Small = styled.small`
  font-size: 12px;
  font-weight: 600;
  color: rgb(255, 255, 255, 0.6);
  letter-spacing: -0.004em;
  display: inline-block !important;
`

const Stepper = ({ stage, steps, callback }) => {
  const handleClick = (e, step) => {
    e.preventDefault()
    callback(step)
  }
  return (
    <StepperWrap>
      {!steps
        ? "Steps Undefined"
        : steps.map((stepName, index) => (
            <>
              <StepWrap
                // onClick={e => handleClick(e, 0)}
                active={stage > index}
                key={`stepperStep${index}${stepName}`}
              >
                <div>
                  <CheckedRight
                    active={stage > index}
                    scale={"scale(0.5)"}
                    style={{ margin: "0 auto" }}
                  />
                  <Small>{stepName}</Small>
                </div>
              </StepWrap>
              {index < steps.length - 1 ? (
                <ProgressBar>
                  <ProgressStatus progress={stage > index ? 100 : 0} />
                </ProgressBar>
              ) : null}
            </>
          ))}
    </StepperWrap>
  )
}

export default Stepper
