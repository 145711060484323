import { Link, navigate } from "gatsby"
import Img from "gatsby-image"
import React, { useState } from "react"

// import NumberFormat from "react-number-format"
import styled from "styled-components"

import {
  CtaPrimary,
  SubmitButton,
  DropdownButton,
  LoadAnimation,
  CloseBtn,
} from "../elements/buttons.js"
import { EssayCardTitle } from "../typography.js"
import Stepper from "./stepper.js"

import { saveAbsEntry, createNewAbsEntry } from "../../services/apiRequests"

//Pallette
// let primaryColor = "rgb(20,20,20)" //"#f50057"
let primaryPink = "#f50057"
// let iconColor = "rgb(240,240,240, 0.95)"
// let yellow = "#F9A826"
const CardWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  background-color: inherit;
  background-clip: border-box;
  border: 1px solid #d2d2d2;
  border-radius: 8px;
  margin-bottom: 20px;
  box-sizing: border-box;
  height: auto;

  @media (min-width: 768px) {
    margin: 10px;
    width: calc(50% - 20px);
  }

  @media (min-width: 919px) {
    margin: 10px;
    width: calc(33% - 20px);
  }
`
const ImgWrap = styled.img`
  max-width: 100%;
  width: 100%;
  max-height: 144px;
  height: auto;
  border-top-left-radius: calc(5px - 1px);
  border-top-right-radius: calc(5px - 1px);
  object-fit: cover;
  @media (min-width: 650px) {
    max-width: 100%;
    width: 100%;
    max-height: 225px;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
`
const ContentWrap = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex: 1 1 auto;
  min-height: 1px;
  padding: 20px 30px;
`
const DetailWrap = styled.div`
  padding: 0 30px 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-flow: row wrap;
`
const Detail = styled.small`
  width: 100%;
  font-size: 14px;
  text-align: ${props => (props.center ? "center" : "left")};
`
const H2 = styled.h2`
  display: block !important;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0;
  text-rendering: auto;
`

const Bookmark = styled.div`
  position: absolute;
  top: 15px;
  right: 0px;
  background-color: #a41034;
  color: #fff;
  padding: 2px 15px;
`
export const Card = ({ src, heading, descrip, detail, price }) => {
  return (
    <CardWrapper>
      <Bookmark>Popular</Bookmark>
      {src ? <ImgWrap src={src} /> : null}
      <Content>
        <ContentWrap>
          <H2>{heading || "MCAT"}</H2>
          <p>
            {descrip ||
              `Designed to help you achieve fluency in the language of business,
            CORe combines Business Analytics, Economics for Managers, and
            Financial Accounting with a final exam.`}
          </p>
        </ContentWrap>
        <DetailWrap>
          <Detail>
            10-17 weeks, 8-15 hrs/week <br /> Apply by January 4
          </Detail>
          <Detail>
            <strong>$2,250</strong>
          </Detail>
          <Detail>Score Guarentee -- Image</Detail>
          {/* //Change position for this one */}
        </DetailWrap>
      </Content>
    </CardWrapper>
  )
}

const McCardWrapper = styled(CardWrapper)`
  background-color: ${props => props.background_color};
  color: white;
  border: 2px solid #ccc;
  cursor: pointer;
  margin: 0 10px;
  @media (min-width: 768px) {
    margin: 10px;
    width: calc(50% - 20px);
  }
  @media (min-width: 919px) {
    margin: 10px;
    width: calc(20% - 20px);
  }
  &:hover {
    transform: scale(1.05);
  }
`
const McH2 = styled(H2)`
  font-size: 15px;
  font-size: 500;
  text-align: center;
`
const McContentWrap = styled(ContentWrap)`
  padding: 10px 20px;
`

export const MiniCard = props => {
  return (
    <McCardWrapper
      onClick={() => {
        navigate(props.link)
      }}
      background_color={props.background_color}
    >
      <Content>
        <McContentWrap>
          <McH2>{props.label}</McH2>
        </McContentWrap>
      </Content>
    </McCardWrapper>
  )
}

//PCC = profileCourseCard. It's a variant of the card above.
const CardWrapperPcc = styled(CardWrapper)`
  padding: 10px 16px;
  margin: 50px auto;
  height: 80px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  @media (min-width: 768px) {
    width: calc(80% - 20px);
  }
  @media (min-width: 919px) {
    width: calc(80% - 20px);
  }
`
const ContentPcc = styled(Content)`
  display: flex;
  flex-flow: row nowrap;
  width: 85%;
`
const ContentWrapPcc = styled(ContentWrap)`
  flex-flow: row nowrap;
  width: 15%;
  padding: 0;
  height: auto;
`

const DetailWrapPcc = styled(DetailWrap).attrs({
  as: "div",
})`
  display: flex;
  flex-flow: row wrap;
  margin: 0;
  padding: 0;
  align-items: center;
  justify-content: center;
  width: 80%;
`
const DetailPcc = styled(Detail).attrs({
  as: "div",
})`
  width: auto;
  margin: auto 25px;
`
const Avatar = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex; /* or inline-flex */
  align-items: center;
  justify-content: center;
  font-size: 22px;
  background-color: #f50057;
  border-radius: 50px;
  color: white;
  text-align: center;
  margin: auto 10px;
`
const H2Pcc = styled(H2)`
  margin: auto 0;
  line-height: inherit;
`

export const ProfileCourseCard = ({
  status,
  serviceName,
  startDate,
  endDate,
}) => {
  const createAvatar = () => {
    if (serviceName !== undefined && serviceName.length > 0) {
      return serviceName[0].toUpperCase()
    }
    return "M"
  }

  return (
    <CardWrapperPcc>
      <ContentPcc>
        <ContentWrapPcc>
          <Avatar>{createAvatar()}</Avatar>
          <H2Pcc>{serviceName || "MCAT"}</H2Pcc>
        </ContentWrapPcc>
        <DetailWrapPcc>
          {startDate ? (
            <DetailPcc>
              <strong>Start Date</strong>
              <div>{startDate || "01 Jan 2021"}</div>
            </DetailPcc>
          ) : null}
          {endDate ? (
            <DetailPcc>
              <strong>End Date</strong>
              <div>{endDate || "01 Mar 2021"}</div>
            </DetailPcc>
          ) : null}
          {status ? (
            <DetailPcc>
              <strong>Service Status</strong>
              <div>{status[0].toUpperCase() + status.substring(1)}</div>
            </DetailPcc>
          ) : null}
        </DetailWrapPcc>
      </ContentPcc>
    </CardWrapperPcc>
  )
}

const TeamImg = styled(Img)`
  width: 45%;
  margin: 25px auto;
  border-radius: 50%;
`

// const LinkButton = styled(OvalAnchor).attrs({
//   as: "button",
// })`
//   outline: none;
//   border: none;
//   padding: 1em 2em;
//   &::placeholder {
//     font: inherit;
//   }
// `
export const TeamCard = ({ src, fluid, alt, name, descrip, detail, link }) => {
  //FLUID IS USED FOR GRAPHQL QUERY. E.g. query: data.humayunImage.childImageSharp.fluid
  // graphql query: query {
  // humayunImage: file(relativePath: { eq: "humayun.png" }) {
  //   childImageSharp {
  //     fluid(maxWidth: 1000) {
  //       ...GatsbyImageSharpFluid
  //     }
  //   }
  // }
  return (
    <CardWrapper>
      <TeamImg fluid={fluid || src} alt={alt || ""} />
      <h4 style={{ textAlign: "center", margin: "0 auto" }}>{name}</h4>
      <Content>
        <ContentWrap>
          <descrip>{descrip || `Designed to help you `}</descrip>
        </ContentWrap>
      </Content>
      <div style={{ margin: "10px auto 25px" }}>
        <Link to={link}>
          <CtaPrimary backgroundColor={"#F50057"} label={"Learn More"} />
        </Link>
      </div>
    </CardWrapper>
  )
}

const PostitWrap = styled.div`
  max-width: 300px;
  position: relative;
  margin: ${props => (props.center ? "0 auto" : "0")};
`
const PostitOuter = styled.div`
  display: flex;
  padding-top: 92.5925926%;
  position: relative;
  width: 100%;
`
const PostitShapeWrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  &::before {
    box-shadow: -2px 2px 15px 0 rgba(0, 0, 0, 0.9);
    background-color: rgba(0, 0, 0, 0.25);
    content: "";
    width: 95%;
    left: 15px;
    height: 75%;
    position: absolute;
    top: 41%;
  }
`
const PostitContent = styled.div`
  background-color: ${props => props.background};
  width: 100%;
  height: 100%;
  display: flex;
  padding: 20px;
  justify-content: center;
  align-items: center;
  font-family: "Kalam", cursive;
  font-size: 1.25rem;
  color: #e3e3e3;
  color: black;
  clip-path: url(#PostitClip);
`
const PostitShape = styled.svg``

export const PostitNote = ({ postItColor, children, center }) => {
  return (
    <PostitWrap center={!!center}>
      <PostitOuter>
        <PostitShapeWrap>
          <PostitShape width="0" height="0">
            <defs>
              <clipPath id="PostitClip" clipPathUnits="objectBoundingBox">
                <path
                  d="M 0 0 Q 0 0.69, 0.03 0.96 0.03 0.96, 1 0.96 Q 0.96 0.69, 0.96 0 0.96 0, 0 0"
                  strokeLinejoin="round"
                  strokeLinecap="square"
                />
              </clipPath>
            </defs>
          </PostitShape>
          <PostitContent background={postItColor}>{children}</PostitContent>
        </PostitShapeWrap>
      </PostitOuter>
    </PostitWrap>
  )
}

const AbsCardWrapper = styled.div`
  box-sizing: border-box;
  width: 300px;
  height: auto;
  border-radius: 10px;
  background-color: rgb(30, 30, 30);
  color: white;
  padding: 15px;
  transition: height 0.2s ease-out;
  margin: 10px 0px;
  &:hover {
    opacity: 95%;
  }
  &::after {
    width: 10px;
    height: 10px;
    color: white;
    text: ${props =>
      (props.value && props.value.length.Tostring()) || "150 chars"};
  }
`
const InnerWrapper = styled.div`
  margin: 0 15px;
  & svg {
    fill: white;
  }
`
const Title = styled.span`
  font: 18px;
  color: inherit;
  font-weight: 500;
`
export const TitleTextArea = styled.textarea`
  background-color: rgb(30, 30, 30, 0.95);
  border-radius: 8px;
  margin: 8px 0px;
  width: 100%;
  font-family: inherit;
  color: inherit;
  font-size: inherit;
  border: none;
  outline: none;
  resize: none;
`

export const InputField = styled(TitleTextArea).attrs({
  as: "input",
})``

const Submit = styled(SubmitButton)`
  background-color: #f50057;
  &:hover {
    background-color: hsla(0, 0%, 100%, 0.2);
  }
`
export const CharIndicator = styled.div`
  display: block;
  box-sizing: border-box;
  color: white;
  width: 50%
  height: 20px;
  font-size: 12px;
  text-align: center;
  background: ${props => (props.length ? "green" : "#CA0B00")};
  border-radius: 15px;
  padding: 4px;
  float: right;
`
// const Date = styled(NumberFormat)`
//   background-color: rgb(30, 30, 30, 0.95);
//   width: 80px;
//   font-family: inherit;
//   color: inherit;
//   font-size: inherit;
//   outline: none;
//   padding: 0px;
//   margin: 0px;
//   border: none;
// `
export const Label = styled.label`
  font-weight: 600;
  font-size: 0.875rem;
  margin-bottom: 2px;
  opacity: 10%;
`
export const Hr = styled.hr`
  opacity: 10%;
  margin: 16px 0px;
`
export const DateWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
`

export const DynamicSubmitBtn = ({ display, loader }) => {
  if (display) {
    return <Submit type="submit">{loader ? <LoadAnimation /> : "Save"}</Submit>
  } else {
    return null
  }
}

export const AbsCard = props => {
  const { cardClicked, setClick, setLoader } = props
  const [changes, setChanges] = useState({ track: [], made: {} })

  const expandCard = () => {
    if (!cardClicked) {
      setClick(true)
    }
  }
  const minimizeCard = () => {
    console.log("click")
    setClick(!cardClicked)
  }
  // const recordChanges = (key, newValue) => {
  //   const trackChangedKey = (trackingArray, key) => {
  //     if (!trackingArray.includes(key)) {
  //       trackingArray.push(key)
  //     }
  //   }
  //   const untrackKey = (trackingArray, key) => {
  //     let keyLocation = trackingArray.indexOf(key)
  //     trackingArray.splice(keyLocation, 1)
  //   }
  //   const makeChange =
  //     props.data[key] !== newValue && changes.made[key] !== newValue
  //   const undoChange =
  //     props.data[key] === newValue && changes.made[key] !== undefined

  //   if (makeChange) {
  //     //make change if new change srsly new.
  //     let clonedObj = Object.assign({}, changes)
  //     clonedObj.made[key] = newValue
  //     trackChangedKey(clonedObj.track, key)
  //     setChanges(clonedObj)
  //   } else if (undoChange) {
  //     //undo change if eventually the same
  //     let clonedObj = Object.assign({}, changes)
  //     delete clonedObj.made[key]
  //     untrackKey(clonedObj.track, key)
  //     setChanges(clonedObj)
  //   }
  // }
  const onSubmit = () => {
    const responseCallback = (err, success) => {
      setLoader(false)
      if (err) {
        throw new Error("Couldn't save changes")
      } else {
        setChanges({}) //reset changes
        setClick(false) //close it
      }
    }
    if (changes.track.length > 0) {
      setLoader(true)
      let isNewCard = !props.data["_id"]
      if (isNewCard) {
        console.log("kk no date")
        changes.made.tag = props.data.tag
        createNewAbsEntry("userid=fjsdnfjdnjs", changes.made, responseCallback)
      } else {
        saveAbsEntry(props.data["_id"], changes.made, responseCallback)
      }
    } else {
      setClick(false)
    }
  }
  return (
    <AbsCardWrapper
      onClick={() => {
        expandCard()
      }}
    >
      <InnerWrapper>
        {!cardClicked ? (
          <Title>{props.title || "Insert title here..."}</Title>
        ) : (
          <>
            <CloseBtn onClick={() => minimizeCard()} />
            {props.children}
          </>
        )}
      </InnerWrapper>
    </AbsCardWrapper>
  )
}

{
  /* <>
            <Label htmlFor="description">Title</Label>
            <TitleTextArea //Add char. counter.
              defaultValue={title}
              type="text"
              placeholder={"Title..."}
              placeholder={"Description..."}
              onChange={e => {
                setTitle(e.target.value)
                recordChanges("title", e.target.value)
                if (e.target.value === "") {
                  e.target.style.height = "initial"
                } else {
                  e.target.style.height = `${e.target.scrollHeight}px`
                }
              }}
            />
            <CharIndicator length={title ? title.length <= 48 : 0}>
              {title.length || 0}/48
            </CharIndicator>
            <br />
            <Hr />
            <Label htmlFor="description">Description</Label>
            <DescripTextArea
              name={"description"}
              defaultValue={description}
              type="text"
              placeholder={"Describe it pls..."}
              onChange={e => {
                setDescription(e.target.value)
                recordChanges("description", e.target.value)
                if (e.target.value === "") {
                  e.target.style.height = "initial"
                } else {
                  e.target.style.height = `${e.target.scrollHeight}px`
                }
              }}
            />

            <CharIndicator length={description ? description.length <= 48 : 0}>
              {description.length || 0}/48
            </CharIndicator>

            <br />
            <Hr />
            <DateWrapper>
              <Controller
                as={Date}
                format="####-##"
                mask={["y", "y", "y", "y", "m", "m"]}
                placeholder="Start Date"
                defaultValue={""}
                name="startDate"
                control={control}
              />
              <span>
                <em>&#8594;</em>
              </span>
              <Controller
                as={Date}
                format="####-##"
                mask={["y", "y", "y", "y", "m", "m"]}
                placeholder="End Date"
                defaultValue={""}
                name="endDate"
                control={control}
              />
            </DateWrapper>

            <Hr />
          </> */
}
const EssayCardWrapper = styled(AbsCardWrapper)`
  @media (min-width: 800px) {
    width: 80%;
  }
  @media (max-width: 800px) {
    width: 450px;
  }
  @media (max-width: 650px) {
    width: 375px;
  }
  @media (max-width: 500px) {
    width: 300px;
  }
`
const FlexRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: ${props => (props.width ? props.width : "auto")};
`
const FlexColumn = styled.div`
  display: flex;
  flex-flow: column nowrap;
  margin: auto 0;
  width: ${props => (props.width ? props.width : "90%")}; ;
`
const StepperWrap = styled.div`
  width: 80%;
  @media (max-width: 550px) {
    display: none;
  }
`
export const EssayCard = props => (
  <EssayCardWrapper>
    <FlexRow>
      <FlexColumn>
        <EssayCardTitle>{props.title}</EssayCardTitle>
        <Label>University of Toronto</Label>
        <StepperWrap>
          <Stepper stage={0} steps={["Started", "Drafted", "Submitted"]} />
        </StepperWrap>
      </FlexColumn>

      <SubmitButton>
        <Link to="/app/response/">Edit</Link>
      </SubmitButton>
    </FlexRow>
  </EssayCardWrapper>
)
export const CourseCardWrapper = styled(EssayCardWrapper)``
// const SubTitle = styled(Label).attrs({
//   as: "div",
// })`
//   color: white;
//   opacity: 0.9;
// `
// const SubTitleText = styled(SubTitle)`
//   opacity: 0.9;
// `
const Marker = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  height: 20px;
  background: ${props => (props.bgColor ? props.bgColor : "#1a8917")};
  padding: 0px 10px;
  float: right;
  color: white;
  font-size: 14px;
  height: 25px;
  line-height: 25px;
  padding: 0 6px;
  text-align: left;
  vertical-align: baseline;
  white-space: normal;
  border: 1px solid rgba(0, 0, 0, 0.15);
  transform: 0.1s background-color, 0.1s border-color, 0.1s color, 0.1s fill;

  margin: 0px 5px;
  float: left;
`
// const EssayCardActions = styled(Marker).attrs({ as: "button" })`
//   transform: scale(1);
//   border-radius: 15px;
//   color: white;
//   border: 1px; solid white;
//   max-width: 50%;
//   margin: 8px 0px;
//   background: transparent;
// `
// const IconRound = styled(Marker)`
//   transform: scale (1);
//   background: black;
//   color: white;
//   font-size: inherit;
//   border-radius: 15px;
//   &:hover {
//     color: ${primaryPink};
//   }
// `
const CourseTitle = styled(EssayCardTitle)``
const FlexRowCourse = styled(FlexRow)`
  display: inline-flex;
  justify-content: start;
  width: auto;
  transform: scale (0.5);
`
export const CourseCard = props => {
  return (
    <CourseCardWrapper>
      <FlexRow>
        <FlexColumn>
          <CourseTitle>MCAT</CourseTitle>
          <Label>Medical College Admission Test</Label>
        </FlexColumn>
        <FlexColumn>
          <FlexRowCourse>
            <Marker>In-Progress</Marker>
            <Marker bgColor={primaryPink}>Extended</Marker>
          </FlexRowCourse>
        </FlexColumn>
        <FlexColumn width={"10%"}>
          <DropdownButton>
            <>Transcript</>
            <>Contract</>
          </DropdownButton>
        </FlexColumn>
      </FlexRow>
      {
        //purchaseDate
        //CourseName
        //status
        //history
        //contract
        //request retake
      }
    </CourseCardWrapper>
  )
}
